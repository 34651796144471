/**
 * Main javascript file for WEG.
 * This file uses revealing module pattern
 */
weg = function($) {

	var transitionSpeed = 300;

    var init = function() {
	    initialiseGlobal();
	    initialiseHome();
	    initialiseRelated();
	    initialiseBlogIndex();
	    initialiseSingle();
	    initialiseCookieWarning();
    };

    /*
     * global
     */
    var initialiseGlobal = function() {
    	
    	//fix for viewport height units
    	//ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    	var vh = window.innerHeight;
    	// Then we set the value in the --vh custom property to the root of the document
    	document.documentElement.style.setProperty('--vh', vh+'px');
    	
    	//add active class to top level menu ul if there are any active children
    	$('#menu-main > li.active').parent().addClass('active');

    	//main nav show / hide
    	$('#nav-trigger').click(function() {
    		$('#header').toggleClass('navopen');
    		$('#header nav').fadeToggle();
    	});  
    	
    	//scroll on button in dark header
		var $headerButton = $('header.dark-header .button');
		if ($headerButton.length && $headerButton.attr('href').substring(0, 1)=='#') {
			$headerButton.click(function(e) {
				e.preventDefault();
				$('html, body').animate({
  		          scrollTop: $($headerButton.attr('href')).offset().top
  		        }, transitionSpeed);
			});
		}

    	//territory menu
    	$('.territories-pulldown li.dropdown').click(function(e) {
    		$(this).toggleClass('click');
			e.stopPropagation();
    	});

    	//prevent clicking on top-level territory menu item
    	$('.territories-pulldown li.dropdown > a').click(function(e) {
    		e.preventDefault();
    	});
    	
    	//for the territory warning
    	$('#territory-warning .dropdown-menu a').click(function(e) {
    		e.preventDefault();
    		var $this = $(this),
    			url = $this.attr('href'),
    			label = $this.find('span.label').text(),
    			flag = $this.find('img').attr('src'),
    			$current = $('#territory-warning li.dropdown > a'),
    			currentUrl = $current.attr('href'),
    			currentLabel = $current.find('span.label').text(),
    			currentFlag = $current.find('img').attr('src');
    		//swap
    		$current.attr('href', url).find('img').attr('src', flag).next('span.label').text(label);
    		$this.attr('href', currentUrl).find('img').attr('src', currentFlag).next('span.label').text(currentLabel);
    		$('#territory-warning li.dropdown').removeAttr('style');
    	});
    	$('#territory-warning button.continue').click(function(e) {
    		//set a cookie
    		Cookies.set('territorywarning', '1', { expires: 30 });
    		location.href = $('#territory-warning li.dropdown > a').attr('href');
    	});
    	$('#territory-warning button.close').click(function(e) {
    		$('#territory-warning').slideUp();
    		//set a cookie
    		Cookies.set('territorywarning', '1', { expires: 30 });
    	});
    	
    };

    /*
     * Homepage
     */
    var initialiseHome = function() {
    	
    	if ($('.page-template-page-homepage').length) {
    		
    		$('input:checkbox').screwDefaultButtons({
    			image: 'url(/wp-content/themes/weg/assets/img/checkboxes.png)',
    			width: 16,
    			height: 16
    		});
    		
    		/**
    		 * styles form field based on whether it's valid
    		 */
    		function checkField(field) {
    			var $element = $(field);
    			if ($element.attr('type')=='checkbox') {
    				$element = $element.parents('.wpcf7-checkbox');
    			}
    			if (validateField(field)) {
    				$element.removeClass('wpcf7-not-valid').addClass('wpcf7-valid');
    			} else {
    				$element.removeClass('wpcf7-valid').addClass('wpcf7-not-valid');
    			}
    		}
    		
    		/**
    		 * tells us whether a particular field type/value is valid
    		 */
    		function validateField(field) {
    			var $this = $(field);
    			if ($this.hasClass('wpcf7-email')) {
    				return validateEmail(field.value);
    			} else if ($this.hasClass('wpcf7-text')) {
    				return field.value!='';
    			} else if ($this.hasClass('wpcf7-select')) {
    				return field.value!='';
    			} else if ($this.attr('type')=='checkbox') {
    				return $this.is(':checked');
    			}
    			//default
    			return false;
    		}
    		
    		/**
    		 * enables the submit button if all fields are valid
    		 */
    		function checkForm() {
    			//loop through each of the fields and break if anything is not valid
    			var allFieldsValid = true;
    			$('.wpcf7-text, .wpcf7-select, .wpcf7-form input[type="checkbox"]').each(function() {
    				if (!validateField(this)) {
    					allFieldsValid = false;
    					return false;
    				}
    			});
				//enable/disable the submit button as required
    			console.log('all fields valid?', allFieldsValid)
				$('.wpcf7-submit').toggleClass('disabled', !allFieldsValid);
    		}
    		
    		function validateEmail(email) {
    		    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    		    return re.test(String(email).toLowerCase());
    		}
    		
    		//when the form values change
    		$('.wpcf7-text, .wpcf7-select, .wpcf7-form input[type="checkbox"]').on('input change', function() {
    			checkField(this);
    			checkForm();
    		});
    		
    		//prevent the form submitting if it's not valid
			$('.wpcf7-submit').click(function() {
				return !$(this).hasClass('disabled');
			});
    	}

    };


    /*
     * Related articles
     * Appears on single article page and homepage
     */
    var initialiseRelated = function() {
    	
    	if ($('aside.related-articles').length) {
    		
    		var $carousel = $('aside.related-articles .owl-carousel');
    		if ($carousel.length) {
    			$carousel.owlCarousel({
            		dots       : true,
					nav        : false,
					margin     : 20,
				    autoWidth  : true,
				    dotsSpeed  : 1000,
			    	responsive : {
					    // breakpoint from 0 up
					    0 : {
					    	items  		: 1
					    },
					    // breakpoint from 960 up
					    960 : {
					    	items     	: 3
					    }
					}
                });
    		}    		
    	}
    }


    /*
     * Javascript for blog index
     */
    var initialiseBlogIndex = function() {
    	
    	if ($('body.blog').length) {

    		$('.articlelist').infinitescroll({
				navSelector  : 'main nav',
			    nextSelector : 'main nav a',
			    itemSelector : '.articlelist article',
			    loading: {
			    	msgText: "<p>Loading more articles</p>",
			        finishedMsg: "<p>There are no more articles to display.</p>",
			        img: "/wp-content/themes/weg/assets/img/ajax-loader2.gif"
			    },
				behavior: "twitter"
			});
    	}
    }
   
    /*
     * Javascript for single article page
     */
    var initialiseSingle = function() {

    	if ($('body.single').length) {  		
    		
    		//for share buttons
    		//twitter
    		$('.share .twitter').click(function(e) {
    			e.preventDefault();
    			showPopup('https://twitter.com/intent/tweet?text='+encodeURIComponent(window.location.href));
    		});
    		//facebook		
    		$('.share .facebook').click(function(e) {
    			e.preventDefault();
    			showPopup('https://www.facebook.com/dialog/share?app_id=574544106606150&display=popup&href='+encodeURIComponent(window.location.href)+'&redirect_uri='+encodeURIComponent(window.location.href));
    		});
    		//linkedin
    		$('.share .linkedin').click(function(e) {
    			e.preventDefault();
    			showPopup('https://www.linkedin.com/cws/share?url='+window.location.href);
    		});    	
    	}    	
    };
    
    /*
     * open a popup window
     * Used by share buttons
     */
    var showPopup = function(url) {
    	window.open(url, 'popUpWindow', 'height=435,width=514,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
    };

    /*
     * cookie warning
     */
    var initialiseCookieWarning = function() {

    	var $warning = $('#cookiewarning');
    	if ($warning.length) {
	    	var cookie = Cookies.get('cookiewarning');
	    	if (typeof cookie == 'undefined' || cookie=='') {
	    		setTimeout(function() {
	    			//show the cookie warning;
	    			$warning.slideDown();
	    		}, 1000);

	    		//close button
	    		$('#cookiewarning button').click(function() {
	    			$warning.slideUp();
	    			Cookies.set('cookiewarning', '1', { expires: 30 });
	    		});
	    	}
    	}
    };    
    
    return{init:init}//return items that are available outside

}(jQuery);


//document ready
jQuery(function() {
	weg.init();
});


